import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class CustomToolbar extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            clicked: true,
            case: "Pending",
        }
    }

    handleCaseClick = async (e, value) => {
        e.preventDefault();
        this.setState({ case: value });
        this.props.handleCaseClickCallback(value);
    }

    render() {
        const { classes } = this.props;
        if (this.props.isERAClaims == true) {
            return (
                <React.Fragment>
                    <Paper square>
                        <Tabs
                            value={this.state.case}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.handleCaseClick.bind(this)}
                            aria-label="disabled tabs example"
                        >
                            <Tab label="Pending" value="Pending" />
                            <Tab label="Completed" value="Completed" />
                        </Tabs>
                    </Paper>

                </React.Fragment>
            );
        }
    }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);
