import React from "react";
import { Auth, API } from 'aws-amplify';
import imgLogo from './imgs/logo-assist-oc.png';
import imgHomeless from './imgs/homeless.png';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { Redirect } from 'react-router'

class App extends React.Component {
    // static contextTypes = { 
    //     router: React.PropTypes.object 
    //   } 
    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false,
            message: "",
            vertical: 'top',
            horizontal: 'right',
            isLoading: false,
            isSingalApplicant: false,
            isSingalPayment: false,

            isUserLoaded: false,
            applicantEmail: null,
            landlordEmail: null,
            landlordMobile: null,
            InitiallandlordMobile: null,
            landlordID: null,
            fundingSource: null,

            name: null,
            applicantID: null,
            dateApplied: null,
            dateApplied_Formatted: null,
            claimStatus: null,

            mysession: "",

            status: "Processing",

            isIrvineApplicant: false,
            userGroup: "",
            paymentId:"",
            reconciliationId:"",
            isShowPaymentBtn: true,
            individualIDFundingSourceTransactionId: "",
            santaAnaTransactionId:''
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true });

        // const { accessToken } = await Auth.currentSession();
        // const cognitogroups = accessToken.payload['cognito:groups'];
        // console.log(cognitogroups);

        //     console.log(cognitogroups[0]);

        // Load the landlord page and return
        // this.setState({ isIrvineApplicant: true, userGroup: "IrvineLandlords" });
        // var res = await this.chekLandlordApplicant();
        // console.log(res);
        // if (res[0][4] == 0) {
        //     // document.getElementById("viewApplicant").disabled = true;
        //     document.getElementById("viewApplicant").style.display = "none";
        //  }
        // if (res[0][4] > 1) {
        //     //  ReactDOM.render(<BrowserRouter><LandlordGrid /></BrowserRouter>, document.getElementById('root'));
        //     this.setState({ isSingalApplicant: false, landlordMobile: res[0][2], InitiallandlordMobile: res[0][2],fundingSource: res[0][3] });

        //     // window.location.assign("/irvineclaim/landloardapplicant");
        // }
        // else {
        //     this.setState({ isSingalApplicant: true, landlordID: res[0][1], landlordMobile: res[0][2], InitiallandlordMobile: res[0][2],fundingSource: res[0][3]});
        //     // window.location.assign("/irvineclaim/landloarddetails/" + res[0][1] +"/S");
        // }
        //ReactDOM.render(<LandlordDetails />, document.getElementById('root'));
        //  }
        try {
            var payRes = await this.chekLandlordPayment();
            // console.log('payRes');
            // console.log(payRes);
            if (payRes[0] == 0) {
                document.getElementById("managePayment").disabled = true;
            } else if (payRes[0] > 1) {
                this.setState({ isShowPaymentBtn: true, isSingalPayment: false, landlordMobile: payRes[1][3], InitiallandlordMobile: payRes[1][3], santaAnaTransactionId: payRes[1][4] });
                //  ReactDOM.render(<BrowserRouter><LandlordGrid /></BrowserRouter>, document.getElementById('root'));
                // window.location.assign("/irvineclaim/landloardapplicant");
            }
            else {
                this.setState({ isShowPaymentBtn: true, isSingalPayment: true, landlordMobile: payRes[1][3], InitiallandlordMobile: payRes[1][3], individualIDFundingSourceTransactionId: payRes[1][0], paymentId: payRes[1][1], reconciliationId: payRes[1][2], santaAnaTransactionId: payRes[1][4] });
                // window.location.assign("/irvineclaim/landloarddetails/" + payRes[0][1] +"/S");
            }
        }
        catch (e) {
            this.setState({ isLoading: false });
            // if (e === "not authenticated") {
            //     await this.signOut();
            // }
            console.log(e);
            //this.setState({ openSnackbar: true, snackbarMessage: "Something went wrong, please contact Administrator.", snackbarMessageClass: 'Mui-filledError' });
            // return;
        }


        this.setState({ isLoading: false });
    }

    async componentDidUpdate(prevProps, prevState) {
        try {
            // console.log('componentDidUpdate called ');
            // console.log(this.props.authState);
            // console.log(this.state.isUserLoaded);
            if (this.props.authState === "signedIn" && this.state.isUserLoaded === false) {
                this.setState({ isLoading: true, isUserLoaded: true, isIrvineApplicant: true, userGroup: "IrvineLandlords" });

                var payRes = await this.chekLandlordPayment();
                //console.log('payRes');
                //console.log(payRes);
                if (payRes[0] == 0) {
                    document.getElementById("managePayment").disabled = true;
                } else if (payRes[0] > 1) {
                    this.setState({ isShowPaymentBtn: true, isSingalPayment: false, landlordMobile: payRes[1][3], InitiallandlordMobile: payRes[1][3], santaAnaTransactionId: payRes[1][4] });
                }
                else {
                    this.setState({ isShowPaymentBtn: true, isSingalPayment: true, landlordMobile: payRes[1][3], InitiallandlordMobile: payRes[1][3], individualIDFundingSourceTransactionId: payRes[1][0], paymentId: payRes[1][1], reconciliationId: payRes[1][2], santaAnaTransactionId: payRes[1][4] });
                    // window.location.assign("/irvineclaim/landloarddetails/" + payRes[0][1] +"/S");
                }
                // Load the landlord page and return
                // var res = await this.chekLandlordApplicant();
                // console.log(res);
                // if (res[0][4] == 0) {
                //    // document.getElementById("viewApplicant").disabled = true;
                //    document.getElementById("viewApplicant").style.display = "none";
                // }
                // if (res[0][4] > 1) {

                //     this.setState({ isSingalApplicant: false, landlordMobile: res[0][2], InitiallandlordMobile: res[0][2],fundingSource: res[0][3] });
                //     // window.location.assign("/irvineclaim/landloardapplicant");
                //     // ReactDOM.render(<BrowserRouter><LandlordGrid /></BrowserRouter>, document.getElementById('root'));
                // }
                // else {
                //     this.setState({ isSingalApplicant: true, landlordID: res[0][1], landlordMobile: res[0][2], InitiallandlordMobile: res[0][2],fundingSource: res[0][3] });
                //     // window.location.assign("/irvineclaim/landloarddetails/" + res[0][1] +"/S");
                // }

                // ReactDOM.render(<LandlordDetails />, document.getElementById('root'));

                this.setState({ isLoading: false });
            }
        }
        catch (e) {
            this.setState({ isLoading: false });
            // if (e === "not authenticated") {
            //     await this.signOut();
            // }
            console.log(e);
            //this.setState({ openSnackbar: true, snackbarMessage: "Something went wrong, please contact Administrator.", snackbarMessageClass: 'Mui-filledError' });
            // return;
        }

    }

    getSessionInfo = async (e) => {
        e.preventDefault();
        var data = await Auth.currentSession()
        // console.log(data.accessToken.jwtToken);
        var log = "JWT Token:\n" + JSON.stringify(data.accessToken.jwtToken);
        alert(log);
        this.setState({ mysession: JSON.stringify(data.accessToken.jwtToken) });
    }

    getData = async (e) => {
        // console.log("get data called");
        // console.log(this.state.applicantEmail);

        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        // console.log(apiName);
        let path = '/getapplicantdetails';
        let myInit = {};

        myInit = { // OPTIONAL
            body: {
                "info": {
                    email: this.state.applicantEmail,
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }
    chekLandlordApplicant = async (e) => {
        // console.log("vchekLandlordApplicant");
        // console.log(this.state.applicantEmail);

        var user = await this.getCurrentUser();
        this.setState({ landlordEmail: user.attributes.email });
        let apiName = process.env.REACT_APP_APIGatewayName;
        // console.log(apiName);
        let path = '/checksantaanalandlordapplicant';
        let myInit = {};

        myInit = { // OPTIONAL
            body: {
                "info": {
                    email: user.attributes.email,
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    chekLandlordPayment = async (e) => {
        var user = await this.getCurrentUser();
        this.setState({ landlordEmail: user.attributes.email });
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/checksantaanalandlordpayment';
        let myInit = {};

        myInit = { // OPTIONAL
            body: {
                "info": {
                    email: user.attributes.email,
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    getCurrentUser = async () => {
        try {
          var user = await Auth.currentAuthenticatedUser();
          return user;
        }
        catch(e){
          this.setState({ isLoading: false });
          if(e === "not authenticated") {
            await this.signOut();
          }
         // return;
        }
    }

    getApplicationInfo = async (e) => {
        // console.log("get application info called");
        this.setState({ isLoading: true });
        var user = await this.getCurrentUser();
        // console.log(user);
        // console.log(user.attributes.email);
        this.setState({ applicantEmail: user.attributes.email });
        this.setState({ isLoading: false, isUserLoaded: true });
    };

    signOut = async () => {
        try {
            await Auth.signOut();
            this.setState({ isUserLoaded: false, applicantEmail: null, landlordEmail: null, landlordMobile: null, InitiallandlordMobile: null, landlordID: null });
            // signout success
            // this.props.loggedIn = false;
        }
        catch (e) {
            console.log(e)
        }
    }

    viewApplication = () => {
        window.location.assign("/irvineclaim/edit/" + this.state.applicantID + "");
    }

    manageDocuments = () => {
        window.location.assign("/irvineclaim/managedocuments/" + this.state.applicantID + "");
    }
    updateLandlordMobile = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordMobile: name });
        // console.log(name);
    }
    updateLandlordPhone = async () => {
        // console.log("update");
        var validationError = false;
        if (this.state.landlordMobile === null || this.state.landlordMobile === "" || this.state.landlordMobile === undefined) {
            this.setState({ landlordMobile_Error: "Landlord mobile is required" });
            // this.spaServiceProviderEmailRef.current.focus();
            validationError = true;
        }
        else {
            if (this.state.landlordMobile.length < 10 || /^\d{3}-?\d{3}-?\d{4}$/.test(this.state.landlordMobile) !== true) {
                this.setState({ landlordMobile_Error: "Invalid mobile Number" });
                //this.landlordPhoneRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordMobile_Error: "" });
            }
        }
        if (validationError == false) {
            this.setState({ isLoading: true });
            var res = await this.updateLandlordDetails();
            this.setState({ isLoading: false, open: true, message: "Lanlord mobile updated" });
            document.getElementById("viewApplicant").disabled = false;
        }

    }
    updateLandlordDetails = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        // console.log(apiName);
        let path = '/updatelandlordmobile';
        let myInit = {};

        myInit = { // OPTIONAL
            body: {
                "info": {
                    email: this.state.landlordEmail,
                    landlordMobile: this.state.landlordMobile
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }
    viewApplicant = (evt) => {
        
        // console.log("click");
        if (this.state.isSingalApplicant == true) {
             window.location.assign("/santaanaclaim/landloarddetails/" + this.state.landlordID + "/"+this.state.fundingSource + "/S");
           // return (<Redirect to={{ pathname: "/irvineclaim/landloarddetails",  state: { individualId: this.state.landlordID , fundingSource:  this.state.fundingSource, mode: "S"} }}/>);
        //    this.context.router.push({ //browserHistory.push should also work here
        //     pathname: "/irvineclaim/landloarddetails", 
        //      state: { individualId: this.state.landlordID , fundingSource:  this.state.fundingSource, mode: "S"} 
        //   }); 
        //  return <Redirect push to={{ pathname: "/irvineclaim/landloarddetails",  state: { individualId: this.state.landlordID , fundingSource:  this.state.fundingSource, mode: "S"} }}/>;
        }
        else {
            window.location.assign("/santaanaclaim/landloardapplicant");
        }
    }

    managePayment = (evt) => {
        // console.log("click");
        if (this.state.isSingalPayment == true) {
            if(this.state.paymentId > 0 ) {
                window.location.assign("/santaanaclaim/landloarddetails/" + this.state.individualIDFundingSourceTransactionId +  "/S");
            } else {
                window.location.assign("/santaanaclaim/landloarddetails/" + +this.state.santaAnaTransactionId +  "/S");
            }
            //window.location.assign("/santaanaclaim/landloarddetails/" + this.state.individualIDFundingSourceTransactionId +  "/S");
        }
        else {
            window.location.assign("/santaanaclaim/landloardpayment");
        }
    }
    render() {
        if (this.props.authState === "signedIn") {
            document.body.classList.remove('login');
            
            return (
                <div >
                    {/* new design starts */}

                    <div>
                        {/* header starts */}
                        <header>
                            <nav className="navbar navbar-light navbar-inner  py-1 py-sm-3" id="headerBg">
                                <a className="navbar-brand" href="#">
                                    <img src={imgLogo} height="38" className="logo" alt="Assist OC logo" loading="lazy" />
                                </a>
                                <div className="ml-auto">
                                    <button className="btn btn-link text-secondary" onClick={this.signOut}>Sign Out <i className="fas fa-power-off ml-1"></i></button>
                                </div>
                            </nav>
                        </header>
                        {/* header ends */}

                        {/* main content starts */}
                        <main className="main">
                            <div className="container-fluid">
                                <div className="card-shadow mb-5 mb-md-0">

                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-6 first text-center">
                                                <div className="welcome-box px-md-5">
                                                    <p className="text-welcome">Welcome!</p>
                                                    <h2 className="text-primary title mb-0">City of Santa Ana, Emergency Rental Assistance</h2>
                                                    <hr />
                                                    <div>
                                                        <div class="form-group row">
                                                            <label class="col-xl-4 col-form-label">Landlord Email:</label>
                                                            <div class="col-xl-8">
                                                                <input type="text" className="form-control" disabled value={this.state.landlordEmail} />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-xl-4 col-form-label">Landlord Mobile:</label>
                                                            <div class="col-xl-8">
                                                                <div class="row"><div class="col-12">
                                                                    <input type="text" className="form-control" disabled  maxlength="20" value={this.state.landlordMobile} />
                                                                </div>
                                                                    {/* <div class="col-3 pl-0">
                                                                        <button class="btn btn-secondary w-100" onClick={(e) => { e.preventDefault(); this.updateLandlordPhone() }}>Save</button>
                                                                    </div> */}
                                                                </div>
                                                                <div class="alert-small-text text-left">{this.state.landlordMobile_Error}</div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-4"></div>
                                                        {/* <div class="col-4">
                                                            <button className="btn btn-primary mt-3" id="viewApplicant" onClick={(e) => { e.preventDefault(); this.viewApplicant() }}>Manage Document <i className="fas fa-file ml-2"></i></button>
                                                        </div> */}
                                                        {/* style={{display: this.state.isShowPaymentBtn?"block":"none"}} */}
                                                        <div class="col-8 text-left">
                                                            <button className="btn btn-primary mt-3" id="managePayment" onClick={(e) => { e.preventDefault(); this.managePayment() }}>Manage Information <i className="fas fa-file ml-2"></i></button>
                                                        </div>
                                                        
                                                    </div>
                                                    {/* <button className="btn btn-secondary mt-3 mr-3" onClick={(e) => { e.preventDefault(); this.updateLandlordPhone() }}>Update <i className="fas fa-paper-plane ml-2"></i></button> */}
                                                    {/* <button className="btn btn-primary mt-3" id="viewApplicant" onClick={(e) => { e.preventDefault(); this.viewApplicant() }}>Manage Document <i className="fas fa-file ml-2"></i></button>
                                                    <button className="btn btn-primary mt-3" style={{marginLeft: this.state.isShowPaymentBtn ? '57px' : '',display: this.state.isShowPaymentBtn?"block":"none"}} id="managePayment" onClick={(e) => { e.preventDefault(); this.managePayment() }}>Manage Payment <i className="fas fa-file ml-2"></i></button> */}
                                                    <hr />
                                                    <p className="text-primary mb-0">Powered by Orange County United Way</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 order-md-first">
                                                <div className="hero-image" style={{ backgroundImage: "url(" + imgHomeless + ")" }}>
                                                    <img src={imgHomeless} className="d-block d-md-none img-fluid w-100" alt="homeless person image" loading="lazy" />
                                                </div>
                                            </div>
                                        </div>

                                        <Backdrop className="MuiBackdrop-root" open={this.state.isLoading}>
                                            <CircularProgress />
                                        </Backdrop>
                                        <Snackbar className="Mui-filledSuccess" anchorOrigin={{ vertical: this.state.vertical, horizontal: this.state.horizontal }}
                                            open={this.state.open}
                                            autoHideDuration={3000}
                                            severity="success"
                                            onClose={() => this.setState({ open: false })}
                                            message={this.state.message}
                                            key={this.state.vertical + this.state.horizontal}
                                        />
                                    </React.Fragment>

                                </div>
                            </div>
                        </main>
                        {/* main content ends */}

                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }
}

export default App;
