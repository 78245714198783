import React from "react";
import { SignIn, SignOut, Greetings } from "aws-amplify-react";
import { CustomSignIn } from "./login";
import App from "./App";
import { Authenticator } from "aws-amplify-react";
import Amplify from 'aws-amplify';

Amplify.configure({
    Auth: {
        identityPoolId: process.env.REACT_APP_UserIdentityPoolID, 
        region: process.env.REACT_APP_Region, 
        userPoolId: process.env.REACT_APP_UserUserPoolId,
        userPoolWebClientId: process.env.REACT_APP_UserUserPoolWebClientId,
    },
    Storage: {
      AWSS3: {
          bucket: process.env.REACT_APP_S3FrontendBucket, //REQUIRED -  Amazon S3 bucket
          region: process.env.REACT_APP_Region, //OPTIONAL -  Amazon service region
      }
    },
    API: {
      endpoints: [
          {
              name: process.env.REACT_APP_APIGatewayName,
              endpoint: process.env.REACT_APP_APIEndpoint
          }
      ]
  }
});

class AppWithAuth extends React.Component {
  render() {
    return (
      <div>
        <Authenticator hide={[SignIn,SignOut,Greetings]}>
          <CustomSignIn />
          <App />
        </Authenticator>
      </div>
    );
  }
}

export default AppWithAuth;
