import React from "react";
import { Auth, API } from 'aws-amplify';
import Backdrop from '@material-ui/core/Backdrop';
import { Storage } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import imgLogo from './imgs/logo-assist-oc.png';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import {
    KmsKeyringBrowser,
    KMS,
    getClient,
    buildClient,
    CommitmentPolicy
} from "@aws-crypto/client-browser";
import * as AWS from "aws-sdk";
import * as util_utf8_node from "@aws-sdk/util-utf8-node";
import { toBase64,fromBase64 } from "@aws-sdk/util-base64-browser";
import { decryptValue } from './utils/helpers/AESUtil';


const { encrypt, decrypt } = buildClient(
    CommitmentPolicy.REQUIRE_ENCRYPT_ALLOW_DECRYPT
);
 

class LandlordDetails extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            documentStatus: "",
            isPaymentUpdated: false,
            staticDoc: "",
            msg: "The information is successfully submitted to City of Santa Ana ERAP Administrator. In case any further information required, the administrator will get in touch with you via email.",
            open: false,
            isLoading: false,
            isSingalApplicant: false,
            individualID: "IRV000000000000",
            fundingSource: "",
            email: "",
            landloardEmail: "",
            applicantName: "",
            address1: "",
            address2: "",
            dateApplied: "",
            requestDate: "",
            landlordName: "",
            landlordEmail: "",
            landlordPhone: "",
            individualCase: "Open",

            //landlord_driver_license: "",
            landlord_driver_license: "",
            landlord_w9: "",
            landlord_participationagreement: "",

            isDrivingLicenceUpload: false,
            isW9Upload: false,
            isParticipateAgreementUpload: false,
            // Documents Uploaded
            //photo_identification_uploaded: false,
            landlord_driver_license_uploaded: false,
            landlord_w9_uploaded: false,
            landlord_participationagreement_uploaded: false,

            //Payment state started
            isSingalPayment: false,

            payeename  : "",
            bankName  : "",
            routingNumber  : "",
            bankAccountType  : "",
            bankAccountNumber  : "",
            
            addresstobeUsedforMailing : "",
            payeenameCheck:"",

            updatedpayeename  : "",
            updatedbankName  : "",
            updatedroutingNumber  : "",
            updatedbankAccountType  : "",
            updatedbankAccountNumber  : "",
            
            updatedpayeenameforCheck : "",
            updatedaddresstobeUsedforMailing : "",

            isPreviouslySelectedACH: "",
            showACH: "none",
            showCheck: "none",
            isReuseBankingDetails: "true",
            isShowReuseBankingDetailsRadioACH: false,
            isShowReuseBankingDetailsRadioCheck: false,
            transactionMode:"ACH",
            isDisableCheckBankDetails: false,
            isDisableACHBankDetails: false,

            santaAnaTransactionId: "",
            paymentId: 0,
            reconciliationId: "",

            openSnackbar: false,
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: '',
            snackbarMessageClass: '',

            openSnackbarACH: false,
            snackbarMessageACH: '',
            
            informationStatus:'',
            validationErrors: {},
            isLockPaymentSection: false,
            issuedDateTime: '',
            keyring: {},
            isPaymentStautsReady: false,

            encryptedRoutingNumber: '',
            encryptedAccountNumber: '',
        }
        this.landlord_driver_license_upload = null;
        // this.photo_identification_upload = null;
        this.landlord_w9_upload = null;
        this.landlord_participationagreement_upload = null;
    }

    async componentDidMount() {
        // console.log(this.props.match.params.id);
        // console.log(this.props.match.params.mode)
        // console.log("router parameter");
        // console.log(this.props.location.state.individualId);
        // console.log(this.props.location.state.fundingSource);
        // console.log(this.props.location.state.mode);
        window.scrollTo(0, 0); // scroll to the top of the window

        if (this.props.match.params.mode == "S") {
            this.setState({ isSingalApplicant: true, msg: "The documents are successfully submitted to City of Santa Ana ERAP Administrator. In case any further information required, the administrator will get in touch with you via email. You can now sign out of this application." });
        }
        else {
            this.setState({ isSingalApplicant: false });
        }

        this.setState({ isLoading: true });
        this.setState({ keyring: await this.setKmsKeyring() });
        var user = await this.getCurrentUser();
        // console.log(user);
        this.setState({ landloardEmail: user.attributes.email, individualID: this.props.match.params.id, fundingSource: this.props.match.params.fundingSource,santaAnaTransactionId: this.props.match.params.transId });
        
        var res = await this.getLandloardInfo();
        // console.log(res);        
        if(res[0][9]==="ACH"){
            this.setState({ isPreviouslySelectedACH: "ACH", showACH : "block",showCheck : "none", transactionMode:"ACH" });
        } else if(res[0][9]==="Check"){
            this.setState({ isPreviouslySelectedACH: "Check", showACH : "none",showCheck : "block", transactionMode:"Check"  });
        } else {
            this.setState({ isPreviouslySelectedACH: "", showACH : "block",showCheck : "none" });
        }
        this.setState({
            applicantName: res[0][0],
            address1: res[0][1],
            address2: res[0][2],
            requestDate: res[0][3],
            landlordName: res[0][4],
            landlordEmail: res[0][5],
            landlordPhone: res[0][6],
            email: res[0][7],
            individualCase: res[0][8],

            payeename  : res[0][10],
            bankName  : res[0][11],
            routingNumber  : (res[0][12] !== undefined && res[0][12] !== "" && res[0][12] !== null) ? await this.decryptData(res[0][12]) : "",
            bankAccountType  : res[0][17],
            bankAccountNumber  : (res[0][13] !== undefined && res[0][13] !== "" && res[0][13] !== null) ? await this.decryptData(res[0][13]) : "",
            
            addresstobeUsedforMailing : res[0][14],
            payeenameCheck:res[0][19],

            santaAnaTransactionId: res[0][15],
            paymentId: res[0][16],

            informationStatus: res[0][18],
            issuedDateTime: res[0][20],
            documentStatus:  res[0][21]
            //reconciliationId: res[18],
        });
        if(this.state.informationStatus === "Ready" || this.state.informationStatus === "Issued" || this.state.informationStatus === "In progress"){
            this.setState({ isPaymentStautsReady: true});
        }
        var temp = await this.getIsShowReuseBankingDetailsRadio();
        //this.setState({ isShowReuseBankingDetailsRadio: temp, isReuseBankingDetails: temp ? "true" : "false"});
        //this.updateReuseBankingDetails(this.state.isReuseBankingDetails);
        if((this.state.informationStatus === "Ready" && this.state.isPreviouslySelectedACH === "ACH") || this.state.informationStatus === "Issued" || this.state.individualCase !== 'Open' || this.state.informationStatus === "In progress"){
            this.setState({ isDisableACHBankDetails: true});
            this.setState({ isLockPaymentSection: true});
        }
        // console.log('isDisableACHBankDetails');
        // console.log(this.state.isDisableACHBankDetails);
        //this.setState({ email: user.attributes.email });
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3FrontendBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });

            var template = await Storage.get(`StaticDocs/LandlordParticipationAgreement/LandlordParticipationAgreement.pdf`);
            this.setState({ staticDoc: template });
        }
        catch (e) {
            console.log(e);
        }
        var res = await this.loadUploadedDocuments();
        // console.log(res);

        this.setState({ isLoading: false });
    }

    encryptData = async (data) => {
        const { result } = await encrypt(this.state.keyring, util_utf8_node.fromUtf8(data));
        // console.log('toBase64(result)');
        // console.log(toBase64(result));
        return toBase64(result);
    }

    decryptData = async (data) => {
        const { plaintext, messageHeader } = await decrypt(this.state.keyring, fromBase64(data));
        return util_utf8_node.toUtf8(plaintext);
    }

    setKmsKeyring = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getuisettings';
        let myInit = {
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        let res1 = await API.get(apiName, path, myInit);
        let accessKeyId = res1 ? await decryptValue(res1.a) : '';
        let secretAccessKey = res1 ? await decryptValue(res1.b) : '';
        AWS.config.credentials = new AWS.Credentials(
            accessKeyId,
            secretAccessKey
        );
        //AWS.config.region = process.env.REACT_APP_Region;
        let AWSCreds = await AWS.config.credentials.get();
        /* The KMS keyring must be configured with the desired CMKs */
        let clientProvider = getClient(KMS, {
            AWSCreds,
            region: process.env.REACT_APP_Region
        });

        let keyring = new KmsKeyringBrowser({
            clientProvider,
            generatorKeyId: process.env.REACT_APP_AWS_KMS_KeyId
        });
        keyring.Credentials = {
            AccessKeyId: accessKeyId,
            SecretAccessKey: secretAccessKey
        };
        return keyring;
    }

    validateControls() {

        // updatedpayeename  : "",
        // updatedbankName  : "",
        // updatedroutingNumber  : "",
        // updatedbankAccountType  : "",
        // updatedbankAccountNumber  : "",
        
        // updatedpayeenameforCheck : "",
        // updatedaddresstobeUsedforMailing : "",

        let formIsValid = true;

        let errors = {};
        if (this.state.transactionMode === "ACH") {
            if (!this.state.updatedpayeename) {
                formIsValid = false;
                errors["updatedpayeename"] = "Payee name is required";
            }
            if (!this.state.updatedbankName) {
                formIsValid = false;
                errors["updatedbankName"] = "Bank Name is required";
            }
            if (!this.state.updatedroutingNumber) {
                formIsValid = false;
                errors["updatedroutingNumber"] = "Routing number is required";
            }
    
            if (!this.state.updatedbankAccountType  || this.state.updatedbankAccountType === "Please Select") {
                formIsValid = false;
                errors["updatedbankAccountType"] = "Bank Account Type is required";
            }
            if (!this.state.updatedbankAccountNumber) {
                formIsValid = false;
                errors["updatedbankAccountNumber"] = "Bank account number is required";
            }
            if (this.state.updatedbankAccountNumber !== "" && this.state.updatedbankAccountNumber !== null && this.state.updatedbankAccountNumber !== undefined) {
                if (/^\d+$/.test(this.state.updatedbankAccountNumber) !== true) {
                    errors["updatedbankAccountNumber"] = "Invalid bank account number";
                    formIsValid = false;
                }
            }
            if (this.state.updatedroutingNumber !== "" && this.state.updatedroutingNumber !== null && this.state.updatedroutingNumber !== undefined) {
                if (/^[a-zA-Z0-9]*$/.test(this.state.updatedroutingNumber) !== true || this.state.updatedroutingNumber.length !== 9) {
                    errors["updatedroutingNumber"] = "Invalid bank routing number";
                    formIsValid = false;
                }
            }
        } else {
            if (!this.state.updatedpayeenameforCheck) {
                formIsValid = false;
                errors["updatedpayeenameforCheck"] = "Payee name is required";
            }
            if (!this.state.updatedaddresstobeUsedforMailing) {
                formIsValid = false;
                errors["updatedaddresstobeUsedforMailing"] = "Address is required";
            }
        }

        // if (!this.state.updatedpayeename) {
        //     formIsValid = false;
        //     errors["Zipcode"] = "Zipcode is required";
        // }
        // if (!this.state.updatedpayeename) {
        //     formIsValid = false;
        //     errors["pyei_Zip"] = "Zipcode is required";
        // }

        // if (!this.state.updatedpayeename) {
        //     formIsValid = false;
        //     errors["pyei_Name"] = "Payee name is required";
        // }
       
        // if (!propertyDetailFields.PropertyOwnerID || propertyDetailFields.PropertyOwnerID === '' || propertyDetailFields.PropertyOwnerID === "Please Select") {
        //     formIsValid = false;
        //     errors["PropertyOwnerID"] = "Owner is required";
        // }

        // if (this.state.w9Uploaded === false) {  // make the w9 required in any case.
        //     formIsValid = false;
        //     errors["w9NotUploaded"] = "W9 document is required";
        // }

        // if (propertyDetailFields["City"] !== "" && propertyDetailFields["City"] !== null && propertyDetailFields["City"] !== undefined) {
        //     var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!namePattern.test(propertyDetailFields["City"])) {
        //         errors["City"] = "Invalid City";
        //         formIsValid = false;
        //     }
        // }
        // if (propertyDetailFields["pyei_City"] !== "" && propertyDetailFields["pyei_City"] !== null && propertyDetailFields["pyei_City"] !== undefined) {
        //     var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!namePattern.test(propertyDetailFields["pyei_City"])) {
        //         errors["pyei_City"] = "Invalid City";
        //         formIsValid = false;
        //     }
        // }



        // if (propertyDetailFields["Zipcode"] !== "" && propertyDetailFields["Zipcode"] !== null && propertyDetailFields["Zipcode"] !== undefined) {
        //     if (/^\d+$/.test(propertyDetailFields["Zipcode"]) !== true) {
        //         errors["Zipcode"] = "Invalid Zip";
        //         formIsValid = false;
        //     }
        // }
        // if (propertyDetailFields["pyei_Zip"] !== "" && propertyDetailFields["pyei_Zip"] !== null && propertyDetailFields["pyei_Zip"] !== undefined) {
        //     if (/^\d+$/.test(propertyDetailFields["pyei_Zip"]) !== true) {
        //         errors["pyei_Zip"] = "Invalid Zip";
        //         formIsValid = false;
        //     }
        // }



        // if (propertyDetailFields["Phone"] !== "" && propertyDetailFields["Phone"] !== null && propertyDetailFields["Phone"] !== undefined) {
        //     if (!propertyDetailFields["Phone"].match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
        //         errors["Phone"] = "Invalid Phone";
        //         formIsValid = false;
        //     }
        // }

        // if (propertyDetailFields["PhoneExtension"] !== "" && propertyDetailFields["PhoneExtension"] !== null && propertyDetailFields["PhoneExtension"] !== undefined) {
        //     if (/^[\d\(\)\+]+$/.test(propertyDetailFields["PhoneExtension"]) !== true) {
        //         errors["PhoneExtension"] = "Invalid Extension";
        //         formIsValid = false;
        //     }
        // }

        // if (propertyDetailFields["Email"] !== "" && propertyDetailFields["Email"] !== null && propertyDetailFields["Email"] !== undefined) {
        //     var secondaryEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //     if (!secondaryEmailPattern.test(propertyDetailFields["Email"])) {
        //         errors["Email"] = "Invalid Email";
        //         formIsValid = false;
        //     }
        // }
        this.setState({ validationErrors: errors });
        return formIsValid;
    }

    getLandloardInfo = async (e) => {
        // console.log(this.state.email);

        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        // console.log(apiName);
        let path = '/getsantaanalandloarddetailsbyemail';
        let myInit = {};

        myInit = { // OPTIONAL
            body: {
                "info": {
                    individualID: this.state.individualID,
                    fundingSource: this.state.fundingSource,
                    santaAnaTransactionId: this.state.santaAnaTransactionId,
                    landlordemail: this.state.landloardEmail
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    getIsShowReuseBankingDetailsRadio = async (e) => {
        let isShowReuseBankingDetailsRadioACH = false;
        let isShowReuseBankingDetailsRadioCheck = false;
        let isDisableCheckBankDetails = this.state.isDisableCheckBankDetails;
        let isDisableACHBankDetails = this.state.isDisableACHBankDetails;
        // if(this.state.transactionMode === "Check") {
        //     if (this.state.addresstobeUsedforMailing && this.state.addresstobeUsedforMailing !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedaddresstobeUsedforMailing: this.state.addresstobeUsedforMailing });
        //     }
        //     if (this.state.payeenameCheck && this.state.payeenameCheck !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedpayeenameforCheck: this.state.payeenameCheck });
        //     }
        // }
        // else {
        //     if (this.state.payeename && this.state.payeename !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedpayeename: this.state.payeename });
        //     }
        //     if (this.state.bankName && this.state.bankName !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedbankName: this.state.bankName });
        //     }
        //     if (this.state.routingNumber && this.state.routingNumber !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedroutingNumber: this.state.routingNumber });
        //     }
        //     if (this.state.bankAccountType && this.state.bankAccountType !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedbankAccountType: this.state.bankAccountType });
        //     }
        //     if (this.state.bankAccountNumber && this.state.bankAccountNumber !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedbankAccountNumber: this.state.bankAccountNumber });
        //     }
        // }

        if (this.state.payeename && this.state.payeename !== "") {
            isShowReuseBankingDetailsRadioACH = true;
            this.setState({ updatedpayeename: this.state.payeename });
        }
        if (this.state.bankName && this.state.bankName !== "") {
            isShowReuseBankingDetailsRadioACH = true;
            this.setState({ updatedbankName: this.state.bankName });
        }
        if (this.state.routingNumber && this.state.routingNumber !== "") {
            isShowReuseBankingDetailsRadioACH = true;
            this.setState({ updatedroutingNumber: this.state.routingNumber });
        }
        if (this.state.bankAccountType && this.state.bankAccountType !== "") {
            isShowReuseBankingDetailsRadioACH = true;
            this.setState({ updatedbankAccountType: this.state.bankAccountType });
        }
        if (this.state.bankAccountNumber && this.state.bankAccountNumber !== "") {
            isShowReuseBankingDetailsRadioACH = true;
            this.setState({ updatedbankAccountNumber: this.state.bankAccountNumber });
        }
        if (this.state.addresstobeUsedforMailing && this.state.addresstobeUsedforMailing !== "") {
            isShowReuseBankingDetailsRadioCheck = true;
            this.setState({ updatedaddresstobeUsedforMailing: this.state.addresstobeUsedforMailing });
        }
        if (this.state.payeenameCheck && this.state.payeenameCheck !== "") {
            isShowReuseBankingDetailsRadioCheck = true;
            this.setState({ updatedpayeenameforCheck: this.state.payeenameCheck });
        }
        
        //isDisableACHBankDetails = isShowReuseBankingDetailsRadio ? (this.state.isReuseBankingDetails === "true" ? true : false) : false;
        // if (isShowReuseBankingDetailsRadio) {
        //     this.setState({ isReuseBankingDetails: "true" });
        //     isDisableACHBankDetails = true;
        //     isDisableCheckBankDetails = true;
        // } else {
        //     isDisableACHBankDetails = false;
        //     isDisableCheckBankDetails = false;
        // }
        this.setState({ 
            isShowReuseBankingDetailsRadioACH: isShowReuseBankingDetailsRadioACH, 
            isShowReuseBankingDetailsRadioCheck: isShowReuseBankingDetailsRadioCheck, 
            isReuseBankingDetails: (isShowReuseBankingDetailsRadioACH || isShowReuseBankingDetailsRadioCheck) ? "true" : "false",
            isDisableACHBankDetails: isShowReuseBankingDetailsRadioACH ? true : false,
            isDisableCheckBankDetails: isShowReuseBankingDetailsRadioCheck ? true : false
        });
    }

    updatePayeename = (evt) => {
        this.setState({ updatedpayeename: evt.target.value });
        this.state.validationErrors["updatedpayeename"] = "";
    }

    updatebankName = (evt) => {
        this.setState({ updatedbankName: evt.target.value });
        this.state.validationErrors["updatedbankName"] = "";
    }

    updateroutingNumber = (evt) => {
        this.setState({ updatedroutingNumber: evt.target.value });
        this.state.validationErrors["updatedroutingNumber"] = "";
    }

    updatebankAccountType = (evt) => {
        this.setState({ updatedbankAccountType: evt.target.value });
    }

    updatebankAccountNumber = (evt) => {
        this.setState({ updatedbankAccountNumber: evt.target.value });
        this.state.validationErrors["updatedbankAccountNumber"] = "";
    }

    updatedpayeenameforCheck = (evt) => {
        this.setState({ updatedpayeenameforCheck: evt.target.value });
        this.state.validationErrors["updatedpayeenameforCheck"] = "";
    }

    updateaddresstobeUsedforMailing = (evt) => {
        this.setState({ updatedaddresstobeUsedforMailing: evt.target.value });
        this.state.validationErrors["updatedaddresstobeUsedforMailing"] = "";
    }

    updateBankAccountType = (evt) => {
        var type = evt.target.value;
        this.setState({ updatedbankAccountType: type });
        this.state.validationErrors["updatedbankAccountType"] = "";
    }
    
    updateReuseBankingDetails(value){
        if ((this.state.isShowReuseBankingDetailsRadioACH || this.state.isShowReuseBankingDetailsRadioCheck)  && value === "true") {
            if(this.state.transactionMode === "ACH"){
                this.setState({ 
                    updatedpayeename  : this.state.payeename,
                    updatedbankName  : this.state.bankName,
                    updatedroutingNumber  : this.state.routingNumber,
                    updatedbankAccountType  : this.state.bankAccountType,
                    updatedbankAccountNumber  : this.state.bankAccountNumber,
                 });
            } else {
                this.setState({ isDisableCheckBankDetails: true});
                this.setState({ 
                    updatedpayeenameforCheck  : this.state.payeenameCheck,
                    updatedaddresstobeUsedforMailing : this.state.addresstobeUsedforMailing,
                 });
            }
        } else {
            if(this.state.transactionMode === "ACH"){
                this.setState({ isDisableACHBankDetails: false});
                this.setState({ 
                    updatedpayeename  : "",
                    updatedbankName  : "",
                    updatedroutingNumber  : "",
                    updatedbankAccountType  : "",
                    updatedbankAccountNumber  : "",
                 });
            } else {
                this.setState({ isDisableCheckBankDetails: false});
                this.setState({ 
                    updatedpayeenameforCheck  : "",
                    updatedaddresstobeUsedforMailing : "",
                 });
            }
        }
    }

    OnUpdateReuseBankingDetails(event) {
        this.setState({ isReuseBankingDetails: event.target.value});
        this.updateReuseBankingDetails(event.target.value)
    }

    OnUpdateTransactionMode(event) {
        this.setState({ transactionMode: event.target.value});
        let isShowReuseBankingDetailsRadioACH = false;
        let isShowReuseBankingDetailsRadioCheck = false;
        let isDisableCheckBankDetails = this.state.isDisableCheckBankDetails;
        let isDisableACHBankDetails = this.state.isDisableACHBankDetails;
        //if(event.target.value === "ACH" && this.state.isPreviouslySelectedACH === "ACH"){
        if(event.target.value === "ACH"){
            if (this.state.payeename && this.state.payeename !== "") {
                isShowReuseBankingDetailsRadioACH = true;
                this.setState({ updatedpayeename: this.state.payeename});
            }
            if (this.state.bankName && this.state.bankName !== "") {
                isShowReuseBankingDetailsRadioACH = true;
                this.setState({ updatedbankName: this.state.bankName});
            }
            if (this.state.routingNumber && this.state.routingNumber !== "") {
                isShowReuseBankingDetailsRadioACH = true;
                this.setState({ updatedroutingNumber: this.state.routingNumber});
            }
            if (this.state.bankAccountType && this.state.bankAccountType !== "") {
                isShowReuseBankingDetailsRadioACH = true;
                this.setState({ updatedbankAccountType: this.state.bankAccountType});
            }
            if (this.state.bankAccountNumber && this.state.bankAccountNumber !== "") {
                isShowReuseBankingDetailsRadioACH = true;
                this.setState({ updatedbankAccountNumber: this.state.bankAccountNumber});
            }
            if(isShowReuseBankingDetailsRadioACH){
                this.setState({ isReuseBankingDetails: "true"});
                isDisableACHBankDetails = true;    
            } else {
                isDisableACHBankDetails = false; 
            }
        }
        // else if(event.target.value === "ACH" && this.state.isPreviouslySelectedACH === "Check"){
        //     if (this.state.payeename && this.state.payeename !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedpayeename: this.state.payeename});
        //     }
        //     if (this.state.bankName && this.state.bankName !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedbankName: this.state.bankName});
        //     }
        //     if (this.state.routingNumber && this.state.routingNumber !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedroutingNumber: this.state.routingNumber});
        //     }
        //     if (this.state.bankAccountType && this.state.bankAccountType !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedbankAccountType: this.state.bankAccountType});
        //     }
        //     if (this.state.bankAccountNumber && this.state.bankAccountNumber !== "") {
        //         isShowReuseBankingDetailsRadio = true;
        //         this.setState({ updatedbankAccountNumber: this.state.bankAccountNumber});
        //     }
        //     if(isShowReuseBankingDetailsRadio){
        //         this.setState({ isReuseBankingDetails: "true"});
        //         isDisableACHBankDetails = true;    
        //     } else {
        //         isDisableACHBankDetails = false; 
        //     }
        // }
        // else if(event.target.value === "Check" && this.state.isPreviouslySelectedACH === "ACH"){
        //     isShowReuseBankingDetailsRadio = false;
        //     isDisableCheckBankDetails = false;
        //     //this.setState({ isDisableCheckBankDetails: false});
        // }
        //else if(event.target.value === "Check" && this.state.isPreviouslySelectedACH === "Check"){
        else if(event.target.value === "Check"){
            //this.setState({ isDisableCheckBankDetails: true}); 
            if (this.state.addresstobeUsedforMailing && this.state.addresstobeUsedforMailing !== "") {
                isShowReuseBankingDetailsRadioCheck = true;
                this.setState({ updatedaddresstobeUsedforMailing: this.state.addresstobeUsedforMailing});
            }
            if (this.state.payeenameCheck && this.state.payeenameCheck !== "") {
                isShowReuseBankingDetailsRadioCheck = true;
                this.setState({ updatedpayeenameforCheck: this.state.payeenameCheck});
            }
            if(isShowReuseBankingDetailsRadioCheck){
                this.setState({ isReuseBankingDetails: "true"});
                isDisableCheckBankDetails = true;    
            } else {
                isDisableCheckBankDetails = false; 
            }
            //isDisableCheckBankDetails = isShowReuseBankingDetailsRadio ? (this.state.isReuseBankingDetails === "true" ? true : false) : false;            
        } 
        // else {
        //     if(event.target.value === "ACH"){
        //         if (this.state.payeename && this.state.payeename !== "") {
        //             isShowReuseBankingDetailsRadio = true;
        //             this.setState({ updatedpayeename: this.state.payeename});
        //         }
        //         if (this.state.bankName && this.state.bankName !== "") {
        //             isShowReuseBankingDetailsRadio = true;
        //             this.setState({ updatedbankName: this.state.bankName});
        //         }
        //         if (this.state.routingNumber && this.state.routingNumber !== "") {
        //             isShowReuseBankingDetailsRadio = true;
        //             this.setState({ updatedroutingNumber: this.state.routingNumber});
        //         }
        //         if (this.state.bankAccountType && this.state.bankAccountType !== "") {
        //             isShowReuseBankingDetailsRadio = true;
        //             this.setState({ updatedbankAccountType: this.state.bankAccountType});
        //         }
        //         if (this.state.bankAccountNumber && this.state.bankAccountNumber !== "") {
        //             isShowReuseBankingDetailsRadio = true;
        //             this.setState({ updatedbankAccountNumber: this.state.bankAccountNumber});
        //         }
        //         //isDisableACHBankDetails = isShowReuseBankingDetailsRadio ? (this.state.isReuseBankingDetails === "true" ? true : false) : false;
        //         if(isShowReuseBankingDetailsRadio){
        //             this.setState({ isReuseBankingDetails: "true"});
        //             isDisableACHBankDetails = true;    
        //         } else {
        //             isDisableACHBankDetails = false; 
        //         }
        //     } else {
        //         if (this.state.addresstobeUsedforMailing && this.state.addresstobeUsedforMailing !== "") {
        //             isShowReuseBankingDetailsRadio = true;
        //             this.setState({ updatedaddresstobeUsedforMailing: this.state.addresstobeUsedforMailing});
        //         }
        //         if (this.state.payeenameCheck && this.state.payeenameCheck !== "") {
        //             isShowReuseBankingDetailsRadio = true;
        //             this.setState({ updatedpayeenameforCheck: this.state.payeenameCheck});
        //         }
        //         //isDisableCheckBankDetails = isShowReuseBankingDetailsRadio ? (this.state.isReuseBankingDetails === "true" ? true : false) : false;            
        //         if(isShowReuseBankingDetailsRadio){
        //             this.setState({ isReuseBankingDetails: "true"});
        //             isDisableCheckBankDetails = true;    
        //         } else {
        //             isDisableCheckBankDetails = false; 
        //         }
        //     }
        //     // isShowReuseBankingDetailsRadio = false;
        //     // isDisableCheckBankDetails = false;
        //     // isDisableACHBankDetails = false;
        // }
        this.setState({ 
            isShowReuseBankingDetailsRadioACH: isShowReuseBankingDetailsRadioACH, 
            isShowReuseBankingDetailsRadioCheck: isShowReuseBankingDetailsRadioCheck, 
            isReuseBankingDetails: (isShowReuseBankingDetailsRadioACH || isShowReuseBankingDetailsRadioCheck)  ? "true" : "false",
            isDisableCheckBankDetails: isDisableCheckBankDetails,
            isDisableACHBankDetails: isDisableACHBankDetails
        });
        //this.updateReuseBankingDetails(this.state.isReuseBankingDetails);
        // console.log('isShowReuseBankingDetailsRadio');
        // console.log(this.state.isShowReuseBankingDetailsRadio);
    }


    updateDocumentStatus = async (action) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        // console.log(apiName);
        let path = '/updatesantaanalandlorddocumentstatus';
        let myInit = {};

        let dateLandlordDocumentSubmitted = '1000-01-01';
        let timeLandlordDocumentSubmitted = null;

        if (action === 'Ready') {
            var d = new Date(new Date().toLocaleString('en-US', { timeZone: 'US/Pacific' }));
            var todaysDate = [
                d.getFullYear(),
                ('0' + (d.getMonth() + 1)).slice(-2),
                ('0' + d.getDate()).slice(-2)
            ].join('-');

            var todaysTime = [
                ('0' + (d.getHours())).slice(-2),
                ('0' + d.getMinutes()).slice(-2),
                ('0' + d.getSeconds()).slice(-2),
            ].join(':');

            dateLandlordDocumentSubmitted = todaysDate;
            timeLandlordDocumentSubmitted = todaysTime;
        }
        else {
            this.setState({ documentStatus: 'Info Requested' });
        }

        myInit = { // OPTIONAL
            body: {
                "info": {
                    individualID: this.state.individualID,
                    fundingSource: this.state.fundingSource,
                    action: action,
                    dateLandlordDocumentSubmitted: dateLandlordDocumentSubmitted,
                    timeLandlordDocumentSubmitted: timeLandlordDocumentSubmitted,
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    handleBack = () => {
        this.setState({ isLoading: false });
        // if (this.state.isSingalApplicant == true) {
        //     window.location.assign("/");
        // }
        // else {
        //     window.location.assign("/santaanaclaim/landloardpayment");
        // }

        if (this.state.isSingalApplicant == true) {
            window.location.assign(`/`);
        }
        else {
            window.location.assign(`/santaanaclaim/landloardpayment`);
        }

    }

    UploadIndividualDocuments = async () => {
        // console.log(this.state.individualID);
        var w9folderName = "";
        var identityfolderName = "";
        var participationfolderName = "";

        if (this.state.fundingSource == "ERA2021") {
            w9folderName = "landlord_w9";
            participationfolderName = "ach_authorization_form";
            identityfolderName = "landlord_driver_license";
        }
        else if (this.state.fundingSource == "ERA2021ReCert") {
            w9folderName = "era2021ReCert_landlord_updated_w9";
            participationfolderName = "era2021ReCert_updated_program_participation_Form";
            identityfolderName = "era2021ReCert_landlord_identity";
        }
        else if (this.state.fundingSource == "ERA2") {
            w9folderName = "era2_landlord_updated_w9";
            participationfolderName = "era2_updated_program_participation_Form";
            identityfolderName = "era2_landlord_identity";
        }
        else {
            w9folderName = "era3_landlord_updated_w9";
            participationfolderName = "era3_updated_program_participation_Form";
            identityfolderName = "era3_landlord_identity";
            // w9folderName = "era2_landlord_updated_w9";
            // participationfolderName = "era2_updated_program_participation_Form";
            // identityfolderName = "era2_landlord_identity";
        }
        // if (this.state.individualID === -1 || this.state.individualID === 0 || this.state.individualID === null || this.state.individualID === 'IRV000000000000') {
        //     // early return
        //     return;
        // }

        if (this.state.individualCase !== 'Open') {
            // early return. don't allow to upload the documents if individual case is set to other than open.
            return;
        }

        this.landlord_driver_license_upload = document.getElementById("landlord_driver_license_upload_id");
        this.landlord_w9_upload = document.getElementById("landlord_w9_upload_id");
        this.landlord_participationagreement_upload = document.getElementById("landlord_participationagreement_upload_id");
        // this.rental_agreement_upload = document.getElementById("rental_agreement_upload_id");

        // Upload the new documents to be uploaded `ERA-IRVINE/${this.state.email}/`
        if (this.landlord_driver_license_upload !== null && this.landlord_driver_license_upload.files != null && this.landlord_driver_license_upload.files.length > 0) {
            try {
                // var result = await Storage.put(`ERA-IRVINE/${this.state.email}/landlord_driver_license/${this.landlord_driver_license_upload.files[0].name}`,
                //     this.landlord_driver_license_upload.files[0],
                //     { contentType: this.landlord_driver_license_upload.files[0].type });
                var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/${identityfolderName}/${this.landlord_driver_license_upload.files[0].name}`,
                this.landlord_driver_license_upload.files[0],
                { contentType: this.landlord_driver_license_upload.files[0].type });

                // console.log(result);
                this.landlord_driver_license_upload = null;

                this.setState({ landlord_driver_license_uploaded: true, isDrivingLicenceUpload: true });
                //this.checkAllDocumentsUpload();
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.landlord_w9_upload !== null && this.landlord_w9_upload.files != null && this.landlord_w9_upload.files.length > 0) {
            try {
                // var result = await Storage.put(`ERA-IRVINE/${this.state.email}/landlord_w9/${this.landlord_w9_upload.files[0].name}`,
                //     this.landlord_w9_upload.files[0],
                //     { contentType: this.landlord_w9_upload.files[0].type });
               
                var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/${w9folderName}/${this.landlord_w9_upload.files[0].name}`,
                    this.landlord_w9_upload.files[0],
                    { contentType: this.landlord_w9_upload.files[0].type });
                // console.log(result);
                this.landlord_w9_upload = null;

                this.setState({ landlord_w9_uploaded: true, isW9Upload: true });
                //this.checkAllDocumentsUpload();
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.landlord_participationagreement_upload !== null && this.landlord_participationagreement_upload.files != null && this.landlord_participationagreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/${participationfolderName}/${this.landlord_participationagreement_upload.files[0].name}`,
                    this.landlord_participationagreement_upload.files[0],
                    { contentType: this.landlord_participationagreement_upload.files[0].type });
                // console.log(result);
                this.landlord_participationagreement_upload = null;

                this.setState({ landlord_participationagreement_uploaded: true, isParticipateAgreementUpload: true });
                //this.checkAllDocumentsUpload();
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
    }

    dummyRequest = (url) => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    // console.log('dummy work completed');
                    resolve();
                },
                2000
            );
        });
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            // signout success
            window.location.assign("/");
        }
        catch (e) {
            console.log(e)
        }
    }

    getCurrentUser = async () => {
        try {
          var user = await Auth.currentAuthenticatedUser();
          return user;
        }
        catch(e){
          this.setState({ isLoading: false });
          if(e === "not authenticated") {
            await this.signOut();
          }
         // return;
        }
    }

    validateUploadFileSizes = () => {
        var validationError = false;

        if (this.state.individualID === -1 || this.state.individualID === 0 || this.state.individualID === null || this.state.individualID === 'IRV000000000000') {
            // early return
            return;
        }

        this.landlord_driver_license_upload = document.getElementById("landlord_driver_license_upload_id");
        this.landlord_w9_upload = document.getElementById("landlord_w9_upload_id");
        this.landlord_participationagreement_upload = document.getElementById("landlord_participationagreement_upload_id");

        let maxAllowedSize = 5 * 1024 * 1024;   // 5 MB

        ///////////////////////////   VALIDATION STARTS   ///////////////////////////

        // Validate the file size which is being uploaded
        if (this.landlord_driver_license_upload !== null && this.landlord_driver_license_upload.files != null && this.landlord_driver_license_upload.files.length > 0) {
            if (this.landlord_driver_license_upload.files[0].size > maxAllowedSize) {
                document.getElementById("landlord_driver_license_size_validation_id").style.display = "block";
                this.landlord_driver_license_upload = null;

                // this.elementRef.current.focus();

                validationError = true;
            }
            else {
                document.getElementById("landlord_driver_license_size_validation_id").style.display = "none";
            }
        }

        if (this.landlord_w9_upload !== null && this.landlord_w9_upload.files != null && this.landlord_w9_upload.files.length > 0) {
            if (this.landlord_w9_upload.files[0].size > maxAllowedSize) {
                document.getElementById("landlord_w9_size_validation_id").style.display = "block";
                this.landlord_w9_upload = null;

                // this.elementRef.current.focus();

                validationError = true;
            }
            else {
                document.getElementById("landlord_w9_size_validation_id").style.display = "none";
            }
        }

        if (this.landlord_participationagreement_upload !== null && this.landlord_participationagreement_upload.files != null && this.landlord_participationagreement_upload.files.length > 0) {
            if (this.landlord_participationagreement_upload.files[0].size > maxAllowedSize) {
                document.getElementById("landlord_participationagreement_size_validation_id").style.display = "block";
                this.landlord_participationagreement_upload = null;

                // this.elementRef.current.focus();

                validationError = true;
            }
            else {
                document.getElementById("landlord_participationagreement_size_validation_id").style.display = "none";
            }
        }

        // Reset elements
        this.landlord_driver_license_upload = null;
        this.landlord_w9_upload = null;
        this.landlord_participationagreement_upload = null;
        ///////////////////////////   VALIDATION ENDS   ///////////////////////////

        if (validationError === true) {
            return false;
        }

        return true;
    }

    // uploadDocumentChange = async () => {
    //     console.log("upload on chagne called");
    //     this.setState({ isLoading: true });
        
    //     if (this.validateUploadFileSizes() === false) {
    //         this.setState({ isLoading: false });
    //         return;
    //     }
    //     try {
    //         var res = await this.UploadIndividualDocuments();
    //         console.log(res);
    //             //alert('Documents uploaded successfully.');
    //         res = await this.loadUploadedDocuments();
    //         console.log(res);
    //     }
    //     catch (err) {
    //         this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
    //         console.log(err)
    //     }
    // }

    uploadDocuments = async () => {
        let isDocumentUploadSuccess = false;
        this.setState({ isLoading: true });
        //this.state.individualCase !== 'Open' || (this.state.isDrivingLicenceUpload == true && this.state.isW9Upload == true && this.state.isParticipateAgreementUpload == true)
        
        // if (this.validateUploadFileSizes() === false) {
        //     result = await this.verifyBankDetails();
        //     this.setState({ isLoading: false });
        //     this.setState({ openSnackbar: true, snackbarMessage: result.isSuccess ? "Payment information stored successfully but few documents are yet to be uploaded." : result.message, snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
        //     // Validation Failed - early return.
        //     return;
        // }
        // // await this.dummyRequest();
        // try {
        //     var res = await this.UploadIndividualDocuments();
        //     console.log(res);
        //         //alert('Documents uploaded successfully.');
        //     res = await this.loadUploadedDocuments();
        //     console.log(res);
        // }
        // catch (err) {
        //     this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
        //     console.log(err)
        // }
        try {
            var result = await this.verifyBankDetails();
            this.setState({ isPaymentStautsReady: result.isSuccess });
            await this.checkAllDocumentsUploadClone(result);
            this.setState({ isLoading: false });
        }
        catch (err) {
            this.setState({ isLoading: false });
            this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
            console.log(err)
        }
    }

    checkAllDocumentsUpload = async () => {
        if (this.state.isDrivingLicenceUpload === true && this.state.isW9Upload === true && this.state.isParticipateAgreementUpload === true) {
            this.setState({ documentStatus: 'Ready' });
            this.updateDocumentStatus("Ready");
            if(this.state.isPaymentUpdated == true || this.state.informationStatus === "Ready") {
                this.setState({ open: true });
            }
            else {
                this.setState({ openSnackbar: true, snackbarMessage: 'Attached documents are uploaded successfully.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
            }
        }
    }

    checkAllDocumentsUploadClone = async (result) => {
        if (this.state.isDrivingLicenceUpload === true && this.state.isW9Upload === true && this.state.isParticipateAgreementUpload === true) {
            if (result.isSuccess) {
                this.setState({ documentStatus: 'Ready' });
                this.updateDocumentStatus("Ready");
                this.setState({ open: true });
                //this.setState({ openSnackbar: true, snackbarMessage: 'Payment information stored successfully but few documents are yet to be uploaded.', snackbarMessageClass: 'Mui-filledSuccess', hideDuration: 3000 });
            } else {
                // this.setState({ openSnackbar: true, snackbarMessage: 'Attached documents are uploaded successfully but rest of the information are yet to be filled.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                if(result.message === 'ACHErrorMessage') {
                    this.setState({ openSnackbarACH: true, snackbarMessageACH: '', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                } else {
                    this.setState({ openSnackbar: true, snackbarMessage: result.message, snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                }
            }
            
        } else {
            if(result.isSuccess) {
                this.setState({
                    openSnackbar: true, snackbarMessage:
                        'Payment information stored successfully. Documents must be uploaded.',
                    snackbarMessageClass: 'Mui-filledSuccess', hideDuration: 3000
                });
                window.location.assign(`/`);
            }
            else {
                if(result.message === 'ACHErrorMessage') {
                    this.setState({ openSnackbarACH: true, snackbarMessageACH: '', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                } else {
                    this.setState({
                        openSnackbar: true, snackbarMessage: result.message,
                        snackbarMessageClass: 'Mui-filledError', hideDuration: 3000
                    });
                }
            }
            // this.setState({
            //     openSnackbar: true, snackbarMessage: result.isSuccess ?
            //         'Payment information stored successfully but few documents are yet to be uploaded.' : result.message,
            //     snackbarMessageClass: result.isSuccess ? 'Mui-filledSuccess' : 'Mui-filledError', hideDuration: 3000
            // });
        }
    }

    fileUploadOnChange = async (e, fileChanged) => {
        this.setState({ isLoading: true });

        let maxAllowedSize = 5 * 1024 * 1024;   // 5 MB

        // console.log(e, fileChanged);

        switch (fileChanged) {
            case 1:
                {
                    this.landlord_driver_license_upload = document.getElementById("landlord_driver_license_upload_id");

                    // Validate the file size which is being uploaded
                    if (this.landlord_driver_license_upload !== null && this.landlord_driver_license_upload.files != null && this.landlord_driver_license_upload.files.length > 0) {
                        if (this.landlord_driver_license_upload.files[0].size > maxAllowedSize) {
                            document.getElementById("landlord_driver_license_size_validation_id").style.display = "block";
                            this.landlord_driver_license_upload = null;
                        }
                        else {
                            document.getElementById("landlord_driver_license_size_validation_id").style.display = "none";
                            try {
                                var res = await this.UploadIndividualDocuments();
                                // console.log(res);
                                    //alert('Documents uploaded successfully.');
                                res = await this.loadUploadedDocuments();
                                // console.log(res);
                                await this.checkAllDocumentsUpload();
                            }
                            catch (err) {
                                this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                                console.log(err)
                            }
                        }
                    }
                }
                break;
            case 2:
                {
                    this.landlord_w9_upload = document.getElementById("landlord_w9_upload_id");

                    // Validate the file size which is being uploaded
                    if (this.landlord_w9_upload !== null && this.landlord_w9_upload.files != null && this.landlord_w9_upload.files.length > 0) {
                        if (this.landlord_w9_upload.files[0].size > maxAllowedSize) {
                            document.getElementById("landlord_w9_size_validation_id").style.display = "block";
                            this.landlord_w9_upload = null;
                        }
                        else {
                            document.getElementById("landlord_w9_size_validation_id").style.display = "none";
                            try {
                                var res = await this.UploadIndividualDocuments();
                                // console.log(res);
                                    //alert('Documents uploaded successfully.');
                                res = await this.loadUploadedDocuments();
                                // console.log(res);
                                await this.checkAllDocumentsUpload();
                            }
                            catch (err) {
                                this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                                console.log(err)
                            }
                        }
                    }
                }
                break;
            case 3:
                {
                    this.landlord_participationagreement_upload = document.getElementById("landlord_participationagreement_upload_id");

                    // Validate the file size which is being uploaded
                    if (this.landlord_participationagreement_upload !== null && this.landlord_participationagreement_upload.files != null && this.landlord_participationagreement_upload.files.length > 0) {
                        if (this.landlord_participationagreement_upload.files[0].size > maxAllowedSize) {
                            document.getElementById("landlord_participationagreement_size_validation_id").style.display = "block";
                            this.landlord_participationagreement_upload = null;
                        }
                        else {
                            document.getElementById("landlord_participationagreement_size_validation_id").style.display = "none";
                            try {
                                var res = await this.UploadIndividualDocuments();
                                // console.log(res);
                                    //alert('Documents uploaded successfully.');
                                res = await this.loadUploadedDocuments();
                                // console.log(res);
                                await this.checkAllDocumentsUpload();
                            }
                            catch (err) {
                                this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                                console.log(err)
                            }
                        }
                    }
                }
                break;
            default:
                break;
        }

        this.setState({ isLoading: false });
    }

    lockDocuments = async () => {
        if (this.state.individualCase !== 'Open') {
            this.landlord_driver_license_upload = document.getElementById("landlord_driver_license_upload_id");
            this.landlord_w9_upload = document.getElementById("landlord_w9_upload_id");
            this.landlord_participationagreement_upload = document.getElementById("landlord_participationagreement_upload_id");

            if (this.landlord_driver_license_upload !== null) {
                this.landlord_driver_license_upload.disabled = true;
            }
            if (this.landlord_w9_upload !== null) {
                this.landlord_w9_upload.disabled = true;
            }
            if (this.landlord_participationagreement_upload !== null) {
                this.landlord_participationagreement_upload.disabled = true;
            }
        }
    }

    loadUploadedDocuments = async () => {
        this.setState({ isLoading: true });

        // var w9folderName = "";
        // if (this.state.fundingSource == "ERA2021") {
        //     w9folderName = "landlord_w9/";
        // }
        // else if (this.state.fundingSource == "ERA2021ReCert") {
        //     w9folderName = "era2021ReCert_landlord_updated_w9/";
        // }
        // else if (this.state.fundingSource == "ERA2") {
        //     w9folderName = "era2_landlord_updated_w9/";
        // }
        // else {
        //     w9folderName = "era2_landlord_updated_w9/";
        //    // folderName = "era3_landlord_updated_w9";
        // }
        var w9folderName = "";
        var identityfolderName = "";
        var participationfolderName = "";

        if (this.state.fundingSource == "ERA2021") {
            w9folderName = "landlord_w9/";
            participationfolderName = "ach_authorization_form/";
            identityfolderName = "landlord_driver_license/";
        }
        else if (this.state.fundingSource == "ERA2021ReCert") {
            w9folderName = "era2021ReCert_landlord_updated_w9/";
            participationfolderName = "era2021ReCert_updated_program_participation_Form/";
            identityfolderName = "era2021ReCert_landlord_identity/";
        }
        else if (this.state.fundingSource == "ERA2") {
            w9folderName = "era2_landlord_updated_w9/";
            participationfolderName = "era2_updated_program_participation_Form/";
            identityfolderName = "era2_landlord_identity/";
        }
        else {
            w9folderName = "era3_landlord_updated_w9";
            participationfolderName = "era3_updated_program_participation_Form";
            identityfolderName = "era3_landlord_identity";
            // w9folderName = "era2_landlord_updated_w9/";
            // participationfolderName = "era2_updated_program_participation_Form/";
            // identityfolderName = "era2_landlord_identity/";
        }
        document.getElementById('landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID <i class="fas fa-asterisk asterisk"></i><div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /><div id="landlord_driver_license_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9 <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">Download Form</a><div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /><div id="landlord_w9_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        document.getElementById('landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href=${this.state.staticDoc}>Download Form</a><div id="landlord_participationagreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_participationagreement_upload_id" /><div id="landlord_participationagreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;

        // Lock documents if individual case is set to other than open
        this.lockDocuments();

      //  if (this.state.individualCase !== 'Open') {
            var fileUploadTag = document.getElementById("landlord_driver_license_upload_id");
            if (fileUploadTag !== null && fileUploadTag !== undefined) {
                if (fileUploadTag.addEventListener) {
                    fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 1) });
                }
                else if (fileUploadTag.attachEvent) {
                    fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 1) });
                }
            }

            fileUploadTag = document.getElementById("landlord_w9_upload_id");
            if (fileUploadTag !== null && fileUploadTag !== undefined) {
                if (fileUploadTag.addEventListener) {
                    fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 2) });
                }
                else if (fileUploadTag.attachEvent) {
                    fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 2) });
                }
            }

            fileUploadTag = document.getElementById("landlord_participationagreement_upload_id");
            if (fileUploadTag !== null && fileUploadTag !== undefined) {
                if (fileUploadTag.addEventListener) {
                    fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 3) });
                }
                else if (fileUploadTag.attachEvent) {
                    fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 3) });
                }
            }
      //  }

        // Reset all the flags
        this.setState({
            landlord_driver_license_uploaded: false,
            landlord_w9_uploaded: false,
            landlord_participationagreement_uploaded: false,
        });

        //get file names for s3 keys
        try {
            // var key = `ERA-IRVINE/${this.state.email}/`;
            var key = `SantaAna/Case/Case_${this.state.individualID}/`;
            var files = await Storage.list(key);
            // console.log(files);
        }
        catch (err) {
            console.log("Error Files - " + err);
            this.setState({ isLoading: false });
            return 1;
        }

        for (var i = 0; i < files.length; i++) {
            if (files[i].key.includes(identityfolderName)) {
                try {
                    var file1 = await Storage.get(files[i].key);
                    this.setState({ landlord_driver_license: file1 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    // if(this.state.mode && this.state.mode == "User"){
                    //     document.getElementById('landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_driver_license}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    // }
                    // else {
                    document.getElementById('landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID <i class="fas fa-asterisk asterisk"></i><div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_driver_license}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag1 = document.getElementById('deleteS3File1');
                    if (aTag1 !== null && aTag1 !== undefined) {
                        if (this.state.individualCase !== 'Open') {
                            aTag1.style.visibility = "hidden";
                        }
                        else {
                            if (aTag1.addEventListener) {
                                aTag1.addEventListener("click", () => { this.deleteS3File(1) });
                            }
                            else if (aTag1.attachEvent) {
                                aTag1.attachEvent("click", () => { this.deleteS3File(1) });
                            }
                        }
                    }
                    // }
                    this.setState({ landlord_driver_license_uploaded: true, isDrivingLicenceUpload: true });
                }
                catch (err) {
                    console.log("Error landlord_driver_license - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID <i class="fas fa-asterisk asterisk"></i><div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /><div id="landlord_driver_license_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                    return 1;
                }
            }
            else if (files[i].key.includes(w9folderName)) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ landlord_w9: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    // if(this.state.mode && this.state.mode == "User"){
                    //     document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_w9}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    // }
                    // else{
                    document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9 <i class="fas fa-asterisk asterisk"></i> <a class="sample-pdf" target="_blank" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">Download Form</a><div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_w9}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File2');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (this.state.individualCase !== 'Open') {
                            aTag2.style.visibility = "hidden";
                        }
                        else {
                            if (aTag2.addEventListener) {
                                aTag2.addEventListener("click", () => { this.deleteS3File(2) });
                            }
                            else if (aTag2.attachEvent) {
                                aTag2.attachEvent("click", () => { this.deleteS3File(2) });
                            }
                        }
                    }
                    // }
                    this.setState({ landlord_w9_uploaded: true, isW9Upload: true });
                }
                catch (err) {
                    console.log("Error landlord_w9 - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9 <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">Download Form</a><div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id"  /><div id="landlord_w9_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                    return 2;
                }
            }
            else if (files[i].key.includes(participationfolderName)) {
                try {
                    var file31 = await Storage.get(files[i].key);
                    this.setState({ landlord_participationagreement: file31 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    // if(this.state.mode && this.state.mode == "User"){
                    //     document.getElementById('landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement<a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3n2o8Cp">Download Form</a><div id="landlord_participationagreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_participationagreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    // }
                    // else{
                    document.getElementById('landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href=${this.state.staticDoc}>Download Form</a><div id="landlord_participationagreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_participationagreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag31 = document.getElementById('deleteS3File3');
                    if (aTag31 !== null && aTag31 !== undefined) {
                        if (this.state.individualCase !== 'Open') {
                            aTag31.style.visibility = "hidden";
                        }
                        else {
                            if (aTag31.addEventListener) {
                                aTag31.addEventListener("click", () => { this.deleteS3File(3) });
                            }
                            else if (aTag31.attachEvent) {
                                aTag31.attachEvent("click", () => { this.deleteS3File(3) });
                            }
                        }
                    }
                    //}
                    this.setState({ landlord_participationagreement_uploaded: true, isParticipateAgreementUpload: true });
                }
                catch (err) {
                    console.log("Error landlord_participationagreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href=${this.state.staticDoc}>Download Form</a><div id="landlord_participationagreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_participationagreement_upload_id" /><div id="landlord_participationagreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                    return 3;
                }
            }
        }

        this.setState({ isLoading: false });

        return 0;
    }

    deleteS3File = async (fileToDelete) => {
        var result = window.confirm("Are you sure you want to delete selected document?");
        if (result === false) {
            return;
        }

        this.setState({ isLoading: true });
        var w9folderName = "";
        var identityfolderName = "";
        var participationfolderName = "";

        if (this.state.fundingSource == "ERA2021") {
            w9folderName = "landlord_w9/";
            participationfolderName = "ach_authorization_form/";
            identityfolderName = "landlord_driver_license/";
        }
        else if (this.state.fundingSource == "ERA2021ReCert") {
            w9folderName = "era2021ReCert_landlord_updated_w9/";
            participationfolderName = "era2021ReCert_updated_program_participation_Form/";
            identityfolderName = "era2021ReCert_landlord_identity/";
        }
        else if (this.state.fundingSource == "ERA2") {
            w9folderName = "era2_landlord_updated_w9/";
            participationfolderName = "era2_updated_program_participation_Form/";
            identityfolderName = "era2_landlord_identity/";
        }
        else {
            w9folderName = "era3_landlord_updated_w9";
            participationfolderName = "era3_updated_program_participation_Form";
            identityfolderName = "era3_landlord_identity";
            // w9folderName = "era2_landlord_updated_w9/";
            // participationfolderName = "era2_updated_program_participation_Form/";
            // identityfolderName = "era2_landlord_identity/";
        }
        try {

           // var key = `ERA-IRVINE/${this.state.email}/`;
           var key = `SantaAna/Case/Case_${this.state.individualID}/`;
           var files = await Storage.list(key);
            // console.log(files);

            // Remove the earlier files for the section for which the new files to be uploaded

            switch (fileToDelete) {
                case 1:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes(identityfolderName)) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                // console.log(res);
                            }
                        }
                        document.getElementById('landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID <i class="fas fa-asterisk asterisk"></i><div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /><div id="landlord_driver_license_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                        this.setState({ landlord_driver_license: "", isDrivingLicenceUpload: false });
                        this.updateDocumentStatus("Info Requested");
                    }
                    break;
                case 2:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes(w9folderName)) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                // console.log(res);
                            }
                        }
                        document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9 <i class="fas fa-asterisk asterisk"></i> <a class="sample-pdf" target="_blank" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">Download Form</a><div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id"  /><div id="landlord_w9_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                        this.setState({ landlord_w9: "", isW9Upload: false });
                        this.updateDocumentStatus("Info Requested");
                    }
                    break;
                case 3:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes(participationfolderName)) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                // console.log(res);
                            }
                        }
                        document.getElementById('landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href=${this.state.staticDoc}>Download Form</a><div id="landlord_participationagreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_participationagreement_upload_id" /><div id="landlord_participationagreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                        this.setState({ landlord_participationagreement: "", isParticipateAgreementUpload: false });
                        this.updateDocumentStatus("Info Requested");
                    }
                    break;
                default:
                    break;
            }
        }
        catch (err) {
            console.log(err);
            this.setState({ msg: "Failed to delete file" });
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });

        var safeload = await this.loadUploadedDocuments();
    }
    handleClose() {
        this.setState({ open: false });
        // if (this.state.isSingalApplicant == false) {
        //     window.location.assign(`/santaanaclaim/landloardpayment`);
        // }
        if (this.state.isSingalApplicant == true) {
            window.location.assign(`/`);
        }
        else {
            window.location.assign(`/santaanaclaim/landloardpayment`);
        }
    }
    getFileIconType(filename) {
        let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
            case 'bmp':
                return 'fas fa-file-image';
            case 'txt':
                return 'fas fa-file-alt';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            default: // any other file
                return 'fas fa-file';
        }
    }

    verifyBankDetails = async () => {
        this.setState({ isLoading: true });

        let isSuccess = false;
        let message = ''

        //Insert record into transaction table
        if (!this.validateControls()) {
            this.setState({ isLoading: false });
            //this.setState({ openSnackbar: true, snackbarMessage: "Please fill all required fields", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
            message = 'Please fill all required fields'
            return { isSuccess, message };
        }

        this.setState({ encryptedRoutingNumber: '', encryptedAccountNumber: '' });

        try {
            if (this.state.transactionMode === "ACH" && this.state.updatedroutingNumber !== undefined && this.state.updatedroutingNumber.trim() !== "") {
                let encryptedRN = await this.encryptData(this.state.updatedroutingNumber.trim());
                this.setState({ encryptedRoutingNumber: encryptedRN });
            }
        } catch (err) {
            console.log(err);
            message = 'Something went wrong validating routing number, please contact Administrator.'
            return { isSuccess, message };
        }

        try {
            if (this.state.transactionMode === "ACH" && this.state.updatedbankAccountNumber !== undefined && this.state.updatedbankAccountNumber.trim() !== "") {
                let encryptedAN = await this.encryptData(this.state.updatedbankAccountNumber.trim());
                this.setState({ encryptedAccountNumber: encryptedAN });
            }
        } catch (err) {
            console.log(err);
            message = 'Something went wrong validating account number, please contact Administrator.'
            return { isSuccess, message };
        }

        if (!this.state.isLockPaymentSection) {
            if(this.state.transactionMode === "ACH" || this.state.transactionMode === "Check") {
                let isSuccessfullyInserted = await this.insertTransactionRecord();
                if(isSuccessfullyInserted == '1'){
                    this.setState({ informationStatus: 'Ready', isPaymentUpdated: true});

                    isSuccess = true;
                    message = 'Information updated successfully.'
                } else {
                    message = this.state.transactionMode === "ACH" ? (isSuccessfullyInserted == '2' ? 'Please contact your bank to get right ACH information. You have exhausted the number of attempts for verifying banking information. Please try again after one hour.' : (isSuccessfullyInserted == '3' ? 'Verification failed. Please retry by refreshing page.' : 'ACHErrorMessage')) : 
                    'Something went wrong, please contact Administrator.';
                }
            }
        }

        this.setState({ isLoading: false, encryptedRoutingNumber: '', encryptedAccountNumber: '' });
        return { isSuccess, message };
    }
    
    insertTransactionRecord = async (e) => {
        // payeename  : res[11],
        // bankName  : res[12],
        // routingNumber  : res[13],
        // bankAccountType  : "",
        // bankAccountNumber  : res[14],
        
        // addresstobeUsedforMailing : res[15],

        //=>entityType,transactionType, payeeName, informationStatus,fullAddress,checkStatus, individualID,fundingSource,paymentId)
        //missing : entityRoutingNumber,entityAccountNumber,entityBankName,reconciliationId
        //ACH :
        
        //=>entityType,transactionType, payeeName, informationStatus,fullAddress,checkStatus, individualID,fundingSource,paymentId)
        //missing : entityRoutingNumber,entityAccountNumber,entityBankName,reconciliationId
        //Check: 
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/addsantaanalandlordtransaction';
        let myInit = {};
        myInit = { // OPTIONAL
            body: {
                "info": {
                    transactionType: this.state.transactionMode,
                    payeeName:this.state.updatedpayeename,
                    payeenameforCheck:this.state.updatedpayeenameforCheck,
                    informationStatus: 'Ready',
                    fullAddress:this.state.updatedaddresstobeUsedforMailing,
                    checkStatus:'',
                    entityRoutingNumber: this.state.encryptedRoutingNumber,
                    entityAccountNumber: this.state.encryptedAccountNumber,
                    entityBankName:this.state.updatedbankName,
                    bankAccountType:this.state.updatedbankAccountType,
                    santaAnaTransactionId: this.state.santaAnaTransactionId,
                    modifiedBy: user.attributes.email,
                    entityType: "Landlord",
                }
                // {"info":{"paymentId":6579,"reconciliationId":null,"entityType":"Landlord","transactionType":"Check",
                // "payeeName":"test payee","informationStatus":"Info-Requested","fullAddress":"2239 W 5th St unit 314,,CA,92703",
                // "checkStatus":"","individualID":45184,"fundingSource":"ERA3","entityRoutingNumber":null,"entityAccountNumber":null,
                // "entityBankName":null}}

            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    closeSnackbar = () => {
        this.setState({ openSnackbar: false });
    }

    render() {
        return (
            <div className="d-flex flex-column sticky-footer-wrapper">
                {/* header starts */}
                <header>
                    <nav className="navbar navbar-light navbar-inner py-3" id="headerBg">
                        <a className="navbar-brand" href="#">
                            <img src={imgLogo} height="38" className="logo" alt="Assist OC logo" loading="lazy" />
                        </a>
                        <div className="ml-auto d-flex">
                            <div style={{ display: this.state.disqualified === true ? 'none' : 'block' }}>
                                <button className="btn btn-light d-inline-block mr-3 ml-3" type="button" onClick={this.handleBack} ><i className="fas fa-reply"></i></button>
                                <button className="btn btn-link text-secondary" onClick={this.signOut}>Sign Out <i className="fas fa-power-off ml-1"></i></button>
                            </div>
                        </div>
                    </nav>
                </header>
                {/* header ends */}

                <main className="main flex-fill">
                    <div className="container-fluid">
                        <div className="box-shadow p-3 mb-3">
                            <h4 className="text-primary">CITY OF SANTA ANA, EMERGENCY RENTAL ASSISTANCE</h4>
                            <hr />
                            <div className="landlord-payment-note">
                                <h6>Instructions:</h6>
                                <div className="note-list">
                                <ol>
                                    <li>Review your contact information.  If anything needs to be updated, please email ERASAConnect@UnitedWayOC.org.  Include your case number, tenant name and address in your email.</li>
                               
                                    <ul>
                                        <li>Please attach a file to be uploaded for all required documents by clicking on 'Choose File' button.</li>
                                        <li>Only 1 file allowed to upload per row.</li>
                                        <li>Size of file to be uploaded should be less or equal to 5 MB.</li>
                                        <li>All the documents with (*) sign are required to be uploaded in order to proceed with application under City of Santa Ana, Emergency Rental Assistance program.</li>
                                        {/* {this.state.individualCase === 'Open' ?
                                            (
                                                <React.Fragment></React.Fragment>
                                            )
                                            :
                                            (
                                                <li>Uploaded documents cannot be changed while the application is under review or once a determination has been made.</li>
                                            )
                                        } */}
                                    </ul>
                                <li>Complete the payment method section. Once added all the required banking information, please click on 'Verify Information' button.</li>
                                </ol>
                                </div>
                            </div>
                            <hr />
                            <h2 className="profile-title d-inline-block"><i class="fas fa-file-alt mr-2"></i>LANDLORD</h2>
                            <hr />
                            <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Applicant Name</label>
                                    <input type="text" className="form-control" disabled value={this.state.applicantName} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Address1</label>
                                    <input type="text" className="form-control" disabled value={this.state.address1} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Address2</label>
                                    <input type="text" className="form-control" disabled value={this.state.address2} />
                                </div>
                            </div>
                            <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Landlord Name (Payee Name)</label>
                                    <input type="text" className="form-control" disabled value={this.state.landlordName} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Landlord Email</label>
                                    <input type="text" className="form-control" disabled value={this.state.landlordEmail} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Landlord Mobile</label>
                                    <input type="text" className="form-control" disabled value={this.state.landlordPhone} />
                                </div>
                            </div>
                            <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Request Date</label>
                                    <input type="text" className="form-control" disabled value={this.state.requestDate} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Applicant ID</label>
                                    <input type="text" className="form-control" disabled value={this.state.individualID} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Funding Source </label>
                                    <input type="text" className="form-control" disabled value={this.state.fundingSource} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Document Status </label>
                                    <input type="text" className="form-control" disabled value={this.state.documentStatus} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Payment Status </label>
                                    <input type="text" className="form-control" disabled value={this.state.informationStatus} />
                                </div>
                               { this.state.informationStatus === "Issued" ? (
                                <div className="col-md-4 form-group">
                                    <label>Issued Timestamp </label>
                                    <input type="text" className="form-control" disabled value={this.state.issuedDateTime} />
                                </div>
                               ) : null }
                            </div>

                            <React.Fragment>
                                <input className="form-control" type="hidden" ></input>
                                <hr />
                                <h2 className="profile-title d-inline-block"><i class="fas fa-file-alt mr-2"></i>Documents</h2>
                                <div className="table-responsive">
                                    <table class="table table-bordered table-cgreen mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Document</th>
                                                <th scope="col">File Name</th>
                                                <th scope="col">Uploaded Date Time</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="landlord_driver_license_id">
                                                <td>Driver’s License or other government issued photo ID <i class="fas fa-asterisk asterisk"></i><div id="landlord_driver_license_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" />
                                                    <div id="landlord_driver_license_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr>
                                            <tr id="landlord_w9_id">
                                                <td>Landlord W9 <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">Download Form</a><div id="landlord_w9_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" />
                                                    <div id="landlord_w9_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr>
                                            <tr id="landlord_participationagreement_id">
                                                <td>Landlord Participation Agreement <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href={this.state.staticDoc}>Download Form</a><div id="landlord_participationagreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_participationagreement_upload_id" />
                                                    <div id="landlord_participationagreement_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="box-shadow p-3 mb-3">
                                    <div className="row" disabled={this.state.isLockPaymentSection}>
                                        <div className="col-auto">
                                            <label>Transaction Mode</label>
                                        </div>
                                        <div className="col-auto pha-radio" disabled={this.state.isLockPaymentSection}>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio1" name="transactionMode" class="custom-control-input"
                                                    checked={this.state.transactionMode && this.state.transactionMode === "ACH"}
                                                    onChange={this.OnUpdateTransactionMode.bind(this)}
                                                    value="ACH" disabled={this.state.isLockPaymentSection} />
                                                <label class="custom-control-label" for="customRadio1">ACH</label>
                                                <Tooltip title="You have received an email notification requesting your participation in the City of Santa Ana CARES for Tenants Program. Direct deposit is the quickest and safest way to receive payment.  To receive a direct deposit payment, please submit your Automated Clearing House (ACH) banking information by completing the form below. In order to receive payment, you must provide your Electronic Bank Routing Number and Account Number. The Electronic Bank Routing Number may be different than routing number on printed checks.  For questions, please contact the representative found on the email correspondence.">
                                                    <i className="fa fa-question-circle ml-1"></i>
                                                </Tooltip>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio2" name="transactionMode" class="custom-control-input"
                                                    checked={this.state.transactionMode && this.state.transactionMode === "Check"}
                                                    onChange={this.OnUpdateTransactionMode.bind(this)}
                                                    value="Check" disabled={this.state.isLockPaymentSection} />
                                                <label class="custom-control-label" for="customRadio2">CHECK</label>
                                                <Tooltip title="Checks will be mailed. 1) Provide your mailing address.2) Confirm the Payee Name for the check">
                                                    <i className="fa fa-question-circle ml-1"></i>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" disabled={this.state.isLockPaymentSection}>
                                        <div className="col-auto"
                                            style={{ display: this.state.isLockPaymentSection || !((this.state.transactionMode == 'ACH' && this.state.isShowReuseBankingDetailsRadioACH) || (this.state.transactionMode == 'Check' && this.state.isShowReuseBankingDetailsRadioCheck)) ? "none" : "block" }}
                                            disabled={(this.state.individualCase !== 'Open' ? true : false) || this.state.isLockPaymentSection}>
                                            <label>Do you want to reuse your existing banking details?</label>
                                        </div>
                                        <div className="col-auto pha-radio"
                                            style={{ display: this.state.isLockPaymentSection || !((this.state.transactionMode == 'ACH' && this.state.isShowReuseBankingDetailsRadioACH) || (this.state.transactionMode == 'Check' && this.state.isShowReuseBankingDetailsRadioCheck)) ? "none" : "block" }}
                                            disabled={(this.state.individualCase !== 'Open' ? true : false) || this.state.isLockPaymentSection}>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio3" name="isReuseBankingDetails" class="custom-control-input"
                                                    checked={this.state.isReuseBankingDetails && this.state.isReuseBankingDetails === "true"}
                                                    onChange={this.OnUpdateReuseBankingDetails.bind(this)}
                                                    value="true" disabled={this.state.isLockPaymentSection} />
                                                <label class="custom-control-label" for="customRadio3">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio4" name="isReuseBankingDetails" class="custom-control-input"
                                                    checked={this.state.isReuseBankingDetails && this.state.isReuseBankingDetails === "false"}
                                                    onChange={this.OnUpdateReuseBankingDetails.bind(this)}
                                                    value="false" disabled={this.state.isLockPaymentSection} />
                                                <label class="custom-control-label" for="customRadio4">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-shadow p-3 mb-3" style={{ display: (this.state.transactionMode && this.state.transactionMode === "ACH") ? "block" : "none" }}>
                                    <div className="row">
                                        <div className="col-auto">
                                            <h4 className="profile-title d-inline-block"><i class="fas fa-money-check-alt mr-2"></i> ACH, DIRECT DEPOSIT</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row p-0">
                                        <div className="col-md-4 form-group">
                                            <label>Name on Bank Account <i class="fas fa-asterisk asterisk"></i></label>
                                            <input type="text" className="form-control" onChange={this.updatePayeename}
                                                disabled={this.state.isDisableACHBankDetails || (this.state.isShowReuseBankingDetailsRadioACH && this.state.isReuseBankingDetails === "true")}
                                                value={this.state.updatedpayeename}
                                                maxlength="140" minlength="1"
                                            />
                                            <span class="alert-small-text">{this.state.validationErrors["updatedpayeename"]}</span>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <label>Bank Name <i class="fas fa-asterisk asterisk"></i></label>
                                            <input type="text" className="form-control" onChange={this.updatebankName}
                                                disabled={this.state.isDisableACHBankDetails || (this.state.isShowReuseBankingDetailsRadioACH && this.state.isReuseBankingDetails === "true")}
                                                value={this.state.updatedbankName}
                                                maxlength="140" minlength="1" />
                                            <span class="alert-small-text">{this.state.validationErrors["updatedbankName"]}</span>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <label>Electronic Bank Routing Number <i class="fas fa-asterisk asterisk"></i></label>
                                            <Tooltip title="Please enter bank routing number without space or special characters.">
                                                    <i className="fa fa-question-circle ml-1"></i>
                                            </Tooltip>
                                            <input type="text" className="form-control" onChange={this.updateroutingNumber}
                                                disabled={this.state.isDisableACHBankDetails || (this.state.isShowReuseBankingDetailsRadioACH && this.state.isReuseBankingDetails === "true")}
                                                value={this.state.updatedroutingNumber}
                                                maxlength="9" minlength="9"
                                            />
                                            <span class="alert-small-text">{this.state.validationErrors["updatedroutingNumber"]}</span>
                                        </div>
                                    </div>
                                    <div className="row p-0">
                                        <div className="col-md-4 form-group">
                                            <label>Bank Account Type <i class="fas fa-asterisk asterisk"></i></label>
                                            {/* <input type="text" className="form-control" value={this.state.bankAccountType} /> */}
                                            <Tooltip title="If this is a company held bank account, please select: Organization. If this is a personal bank account, please select: Personal.">
                                                    <i className="fa fa-question-circle ml-1"></i>
                                            </Tooltip>
                                            <select className="form-control" onChange={this.updateBankAccountType}
                                                disabled={this.state.isDisableACHBankDetails || (this.state.isShowReuseBankingDetailsRadioACH && this.state.isReuseBankingDetails === "true")}
                                                value={this.state.updatedbankAccountType}>
                                                <option>Please Select</option>
                                                <option value="Organization">Organization</option>
                                                <option value="Personal">Personal </option>
                                            </select>
                                            <span class="alert-small-text">{this.state.validationErrors["updatedbankAccountType"]}</span>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <label>Bank Account Number <i class="fas fa-asterisk asterisk"></i></label>
                                            <Tooltip title="Please enter bank account number without space or special characters.">
                                                    <i className="fa fa-question-circle ml-1"></i>
                                            </Tooltip>
                                            <input type="text" className="form-control" onChange={this.updatebankAccountNumber}
                                                disabled={this.state.isDisableACHBankDetails || (this.state.isShowReuseBankingDetailsRadioACH && this.state.isReuseBankingDetails === "true")}
                                                value={this.state.updatedbankAccountNumber}
                                                maxlength="17" minlength="1" />
                                            <span class="alert-small-text">{this.state.validationErrors["updatedbankAccountNumber"]}</span>
                                        </div>
                                    </div>
                                    <div className="text-right mt-3 mt-sm-0">
                                        <button class="btn btn-primary mb-3 mb-sm-0" 
                                        disabled={(this.state.transactionMode == "ACH" && this.state.isPaymentStautsReady && this.state.isPreviouslySelectedACH == "ACH" )||  this.state.individualCase !== 'Open'} 
                                        onClick={this.uploadDocuments}><i class="fas fa-arrow-right"></i> Verify Information</button>
                                    </div>
                                </div>

                                <div className="box-shadow p-3 mb-3" style={{ display: (this.state.transactionMode && this.state.transactionMode === "Check") ? "block" : "none" }}>
                                    <div className="row">
                                        <div className="col-auto">
                                            <h4 className="profile-title d-inline-block"><i class="fas fa-money-check-alt mr-2"></i>Check</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row p-0">
                                        <div className="col-md-4 form-group">
                                            <label>Address to be used for mailing <i class="fas fa-asterisk asterisk"></i></label>
                                            <input type="text" className="form-control"
                                                disabled={this.state.isDisableCheckBankDetails || this.state.individualCase !== 'Open'}
                                                onChange={this.updateaddresstobeUsedforMailing} value={this.state.updatedaddresstobeUsedforMailing} />
                                            <span class="alert-small-text">{this.state.validationErrors["updatedaddresstobeUsedforMailing"]}</span>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <label>Payee Name <i class="fas fa-asterisk asterisk"></i></label>
                                            <input type="text" className="form-control"
                                                disabled={this.state.isDisableCheckBankDetails || this.state.individualCase !== 'Open'}
                                                onChange={this.updatedpayeenameforCheck} value={this.state.updatedpayeenameforCheck}
                                                maxlength="140" minlength="1" />
                                            <span class="alert-small-text">{this.state.validationErrors["updatedpayeenameforCheck"]}</span>
                                        </div>
                                    </div>
                                    <div className="text-right mt-3 mt-sm-0">
                                        <button class="btn btn-primary mb-3 mb-sm-0" 
                                        disabled={this.state.individualCase !== 'Open'} 
                                        onClick={this.uploadDocuments}><i class="fas fa-arrow-right"></i> Verify Information</button>
                                    </div>

                                </div>

                                {/* <div className="text-right mt-3 mt-sm-0">
                                    <button class="btn btn-primary mb-3 mb-sm-0" disabled={this.state.individualCase !== 'Open' || (this.state.isDrivingLicenceUpload == true && this.state.isW9Upload == true && this.state.isParticipateAgreementUpload == true)} onClick={this.uploadDocuments}><i class="fas fa-arrow-right"></i> Upload</button>
                                </div> */}
                                <Backdrop open={this.state.isLoading}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar
                                    anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                                    autoHideDuration={3000}
                                    open={this.state.openSnackbar}
                                    onClose={() => this.setState({ openSnackbar: false })}
                                    message={this.state.snackbarMessage}
                                    key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                                    className={this.state.snackbarMessageClass}
                                    // action={
                                    //     <React.Fragment>
                                    //         <IconButton aria-label="close" color="inherit" onClick={this.closeSnackbar.bind()}>
                                    //             <CloseIcon />
                                    //         </IconButton>
                                    //     </React.Fragment>
                                    // }
                                />

                                <Snackbar
                                    anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                                    autoHideDuration={6000}
                                    open={this.state.openSnackbarACH}
                                    onClose={() => this.setState({ openSnackbarACH: false })}
                                    message={this.state.snackbarMessageACH}
                                    key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                                    className={this.state.snackbarMessageClass}
                                    action={
                                        <span>
                                            Account information cannot be processed.
                                            <p>
                                                (1) Please verify and re-enter the account number, routing number and
                                                account type. <br />
                                                (2) If the system is unable to verify your information a second time,
                                                please select option to receive rent by check.
                                            </p>
                                            For further assistance, contact <a style={{color : '#F8ECB7'}} href="mailto:ERASAConnect@UnitedWayOC.org">ERASAConnect@UnitedWayOC.org</a>
                                        </span>
                                    }
                                />

                                {/* <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} onClose={() => this.setState({ openSnackbar: false })}
                                key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                                className={this.state.snackbarMessageClass}
                                anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                                >
                                    <Alert severity={this.state.snackbarSeverityACH}>
                                        <span>
                                            Account information cannot be processed.
                                            <p>
                                                (1) Please verify and re-enter the account number, routing number and
                                                account type. <br />
                                                (2) If the system is unable to verify your information a second time,
                                                please select option to receive rent by check.
                                            </p>
                                            For further assistance, contact <a href="mailto:ERASAConnect@UnitedWayOC.org">ERASAConnect@UnitedWayOC.org</a>
                                        </span>
                                    </Alert>
                                </Snackbar> */}
                                <Dialog open={this.state.open} className="thankyoudialog" onClose={() => this.handleClose()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                    <DialogTitle id="alert-dialog-title">{"Information Submitted!"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {this.state.msg}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => this.handleClose()} color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </React.Fragment>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default LandlordDetails;
