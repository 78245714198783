import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import AppWithAuth from "./AppWithAuth";
import LandlordDetails from './landlordDetails'
import LandlordGrid from './landlordGrid'
import LandlordPaymentDetails from './landlordPaymentDetails'
import LandlordPaymentGrid from './landlordPaymentGrid'
function Routes() {
    const { pathname } = useLocation();

    return (
        <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <Route exact path="/" component={AppWithAuth}></Route>
            <Route exact path="/santaanaclaim/landloarddetails/:id/:fundingSource/:transId/:mode" component={LandlordDetails} ></Route>
            <Route exact path="/santaanaclaim/landloardapplicant" component={LandlordGrid} ></Route>
            {/* <Route exact path="/santaanaclaim/landloarddetails" component={LandlordDetails} ></Route> */}
            {/* <Route exact path="/santaanaclaim/landloardpaymentdetails/:id/:fundingSource/:mode" component={LandlordPaymentDetails} ></Route> */}
            <Route exact path="/santaanaclaim/landloarddetails/:id/:mode" component={LandlordPaymentDetails} ></Route>
            <Route exact path="/santaanaclaim/landloardpayment" component={LandlordPaymentGrid} ></Route>
            
        </Switch>
    );
}

export default Routes;