import React from "react";
import { SignIn } from "aws-amplify-react";
import imgLogo from './imgs/logo-assist-oc.png';
import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';

export class CustomSignIn extends SignIn {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            isLoading: false
        }

        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
        // this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    handleLoginClick = async () => {
        this.setState({ isLoading: true, });
        await super.signIn();
        this.setState({ isLoading: false, });
    }

    //   handleLoginClick() {
    //     if (document.getElementById("username").value === 'test' && document.getElementById("password").value === 'test') {
    //       this.setState({
    //         clicked: true
    //       });
    //     }
    //     else {
    //       alert("Invalid username or password!");
    //     }
    //   }

    showComponent(theme) {
        // if (this.state.clicked === true) {
        //   return <App loggedIn={this.state.clicked} />;
        // }
        // else {
        document.body.classList.add('login');
        return (
            <div>
                <img className="my-4 pt-2 d-block d-md-none" src={imgLogo} height="38" alt="ASSOSTOC"></img>
                <div className="login-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-5 p-0">
                                <React.Fragment>
                                    <div className="login-box">
                                        <h2 className="mb-4 text-center text-md-left font-weight-bold">Sign In</h2>
                                        <div className="form-group form-group-md">
                                            <label htmlFor="username">Email</label>
                                            <input type="text" className="form-control" id="username" key="username" name="username" onChange={this.handleInputChange} placeholder="Enter Email" />
                                        </div>
                                        <div className="form-group form-group-md mb-0">
                                            <label htmlFor="password">Password</label>
                                            <input type="password" className="form-control" id="password" key="password" name="password" onChange={this.handleInputChange} placeholder="Enter Password" />
                                        </div>
                                        <div className="text-right mb-4 mt-2">
                                            <a href="javascript:void(0);" onClick={() => {
                                                super.changeState("forgotPassword");
                                                document.body.classList.add('login-fp');
                                            }}
                                            >Forgot Password?</a>
                                        </div>
                                        <button type="button" onClick={this.handleLoginClick} className="btn btn-secondary btn-block">Login <i className="fas fa-lock ml-2"></i></button>
                                    </div>
                                    <Backdrop className="MuiBackdrop-root" open={this.state.isLoading} >
                                        <CircularProgress />
                                    </Backdrop>
                                </React.Fragment>
                            </div>
                            <div className="col-md-7 order-md-first bg-white p-0">
                                <div className="p-3 p-md-5">
                                    <img className="logo-login d-none d-md-block" src={imgLogo} alt="ASSOSTOC"></img>
                                    <h3 className="title mb-3 text-primary">City of Santa Ana, Emergency Rental Assistance</h3>
                                    <p>Welcome!  Log in to submit requested documents.</p>
                                    <ol>
                                        <li>Use the username and temporary password sent to you in your email.</li>
                                        <li>Create your new password by following the instructions.</li>
                                        <li>Once logged in, be prepared to upload your documents.</li>
                                    </ol>
                                    <p>We request that you complete all three steps within five (5) days of receiving your email.</p>
                                    <p>For more information about the ERAP program and eligibility, please visit the <a target="_blank" href="https://www.santa-ana.org/rental-assistance">ERAP website</a>.</p>
                                    <hr className="my-4"></hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        // }
    }
}